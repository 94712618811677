import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';


const Head = ({ title }) => {

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const siteData = data.site.siteMetadata;
  return (
    <Helmet>
      <meta name="facebook-domain-verification" content="k8tphues2vw32zk57e9fgq65tuogo3" />
      <meta name="robots"
        content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
      <title>{`${title} | ${siteData.title}`}</title>
      <link rel="prefetch" href="https://use.typekit.net/qip4dcy.css" as="style" crossOrigin />
      <link rel="stylesheet" href="https://use.typekit.net/qip4dcy.css" />
      <html lang="en-US" />
    </Helmet>

  )
}

export default Head;