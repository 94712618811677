import React from 'react';
import FreePoster from '../components/freePoster';
import '../styles/index.scss';


const FreePosterOffer = () => {
  return (
    <FreePoster
      sourceName='group[80976][8]'
      sourceValue='8'
    />
  )
}

export default FreePosterOffer;
